<template>
  <b-modal centered :title="title" size="xl" :id="target" hide-footer>
    <slot />
  </b-modal>
</template>

<script>
import {BModal} from "bootstrap-vue"

export default {
  components: {
    BModal
  },
  props: ["title", "target"]
}
</script>

<style scoped lang="scss">

</style>
